import React from "react";
import WinningItems from "../components/AC-WinningItems/WinningItems";

import Layout from "../other/layout";
import SEO from "../other/seo";

const WinningItemsComp = () => {
  return (
    <Layout>
      <SEO title="Cookie Page" />
      <WinningItems />
    </Layout>
  );
};

export default WinningItemsComp;
