import { FormattedNumber } from "react-intl";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
import { DEFAULT_CURRENCY, LINK_DISTRIBUTION, VID } from "../../project-config";
import { addToCartAuctionItems } from "../../redux/actions/productAction";
import * as classes from "./WinningItems.module.css";
function WinningItems() {
  const [winningItems, setWinningItems] = useState([]);
  const dispatch = useDispatch();
  const clientId = useSelector(
    state => state.loginReducer.clientId,
    shallowEqual
  );

  useEffect(() => {
    const asynFetch = async () => {
      const result = await fetch(
        `${LINK_DISTRIBUTION}/getwinningitems.ajx?vid=${VID}&clientid=${clientId}`
      );
      const json = await result.json();
      console.log("json123", json);
      if (json) {
        setWinningItems(json);
      }
    };
    asynFetch();
  }, [clientId]);

  console.log("winningItems", winningItems);
  const addAllProducts = () => {
    return addToCartAuctionItems(winningItems.length, winningItems, VID, false);
  };

  if (!clientId) {
    return (
      <div className={classes.loginContainer}>
        <h1>Login to see your winning items!</h1>
        <button
          className={classes.button}
          onClick={() => document.getElementById("login-icon-btn").click()}
        >
          Login
        </button>
      </div>
    );
  }

  if (!winningItems.length) {
    return (
      <div className={classes.container}>
        <h1>You have no winning items!</h1>
      </div>
    );
  }
  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        {winningItems.map(({ itemCode, title, price, image }, index) => {
          return (
            <div className={classes.item} key={index}>
              <img
                className={classes.image}
                src={`https://ik.imagekit.io/ofb/${process.env.GATSBY_IMAGEKIT_DIRECTORY}/store/${image}?tr=ar-4-3,dpr-2,r-20,pr-true,f-auto`}
                alt={title}
              />
              <div className={classes.itemInfo}>
                <h3
                  style={{
                    margin: "10px"
                  }}
                >
                  {title}
                </h3>
                <FormattedNumber
                  value={price}
                  style="currency"
                  currency={DEFAULT_CURRENCY}
                />
              </div>
            </div>
          );
        })}
      </div>
      <button
        className={classes.button}
        onClick={() => dispatch(addAllProducts())}
      >
        Add All Products to Cart
      </button>
    </div>
  );
}

export default WinningItems;
